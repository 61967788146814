@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Antique-Cherry';
  src: url('./fonts/Antique_Cherry-Personal_Use.ttf') format('truetype');
}

@font-face {
  font-family: 'Heebo';
  src: url('./fonts/Heebo-Regular.woff2') format('woff2'),
    url('./fonts/Heebo-Regular.woff') format('woff'),
    url('./fonts/Heebo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Heebo-Bold';
  src: url('./fonts/Heebo-Bold.woff2') format('woff2'),
    url('./fonts/Heebo-Bold.woff') format('woff'),
    url('./fonts/Heebo-Bold.ttf') format('truetype');
}

.hero-image .say-hi {
  bottom: 23px;
  left: 160px;
}
